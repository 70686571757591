<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>SEPTEMBER BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/214762-september-birthstone-sapphire-1280x550.jpg" alt="">
                    <p class="mt-4">
                       The September birthstone is sapphire – a gem that’s been cherished for thousands of years. Although the term sapphire usually refers to the blue variety of corundum (ruby is the red variety), this birthstone comes in a rainbow of other colors. Sapphires have been long associated with royalty and romance and are also said to symbolize fidelity and the soul. “Sapphire” comes from the Greek word sappheiros and blue sapphire is one of the most popular colored stones. Read on to learn more about the September birthstone, specifically its history and where it can be found.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>SAPPHIRE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  The September birthstone has traditionally symbolized sincerity, truth, faithfulness and nobility. For countless centuries, sapphire has adorned royalty and the robes of the clergy. The elite of ancient Greece and Rome believed that blue sapphires protected their owners from harm and envy. Clerics of the Middle Ages wore sapphires because they symbolized Heaven. Ancient Persians believed the earth actually rested on a giant sapphire, which made the sky blue.</p>
                  <p>

                The September birthstone was reputed to have healing powers as well. Medieval Europeans believed that sapphire cured plague boils and diseases of the eye. The sapphire birthstone was also thought to be an antidote to poison.</p>
                <p>
                Famous sapphires include the Rockefeller Sapphire, a 62.02 carat (ct) rectangular step cut stone that was unearthed in Myanmar (Burma). Acquired in 1934 by financier and philanthropist John D. Rockefeller, Jr. (1874–1960) from an Indian maharaja, the gem was recut and remounted over the years. The sapphire was first set as a brooch and later as a ring featuring two cut-cornered triangular diamond side stones. Perhaps the best-known sapphire in recent years is the 12 ct blue gem surrounded by diamonds in the sapphire engagement ring first worn by Princess Diana and then given by her son to Kate Middleton, now Duchess of Cambridge.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/142649-sapphire-ring-900x507.png" alt="">
                <p>
                   
                In addition to being the September birthstone, sapphire is also the gem commemorating the 5th and 45th wedding anniversaries.


                </p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'SeptemberBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
