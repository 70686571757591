<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>APRIL BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/206728-april-birthstone-diamond-1280x550.jpg" alt="">
                    <p class="mt-4">
                       Sparkling with an internal fire all its own, diamond is one of the world’s most sought-after and adored gemstones. Those born in April are lucky enough to call this scintillating gem their birthstone, a symbol of clarity and strength. Diamond is so strong, in fact, that its name comes from the Greek word "adamas," which means “invincible” or “unbreakable.” The timeless charm of diamond was cherished long before it became the April birthstone, and the places where diamond comes from are as fascinating as the lore that surrounds it.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>DIAMOND BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   Our love for the April birthstone started in India, where diamonds were gathered from the country’s rivers and streams. Traded as early as the fourth century BCE, diamonds were coveted by royalty and the wealthy. Later, caravans brought Indian diamonds, along with other exotic merchandise, to medieval markets in Venice. By the 1400s, diamonds were becoming fashionable accessories for Europe’s elite. The first diamond engagement ring on record was given by Archduke Maximillian of Austria to his betrothed, Mary of Burgundy, in 1477. Recent evidence supports the origin of the legendary 45.52 carat (ct) blue Hope diamond in India’s Golconda mining area and its sale to King Louis XIV of France (then known as the French Blue diamond) in 1668.
                   </p>

                <p>In the early 1700s, as India’s diamond supplies began to decline, Brazil emerged as an important source. Diamonds were discovered when gold miners sifted through gravels on the banks of the Jequitinhonha River in Minas Gerais. Brazil dominated the diamond market for more than 150 years.</p>
                <p>
                The discovery of diamonds near Kimberley, South Africa, in the late 1860s marked the beginning of the modern diamond market. Entrepreneur Cecil Rhodes established De Beers Consolidated Mines Limited in 1888, and by 1900 De Beers controlled an estimated 90 percent of the world’s production of rough diamonds. The largest diamond ever found – at 3,106 ct (621 grams) – was recovered from South Africa’s Premier mine in 1905. From it was cut the pear shaped 530 ct Cullinan I diamond, also known as the Great Star of Africa, which is now set in the Royal Sceptre with Cross and housed with the other Crown Jewels in the Tower of London.
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/194161-hope-diamond-750x750.png" alt="">
                <p>
                    An anonymous second-century poet maintained that this birthstone for April warded off the evil eye – a malevolent look believed to cause sickness, poverty and even death. The April birthstone was even believed to have healing powers. Over the centuries, the diamond birthstone was thought to be an antidote to poison and provide protection against the plague.  Some claim that it is a boon for longevity, strength, beauty and happiness.</p>
                <p>
                In addition to being the April birthstone, diamond is the gift of choice for the 60th and 75th wedding anniversaries. And, of course, today the diamond engagement ring has become a near-universal symbol of love and marriage.

                </p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'AprilBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
    mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
