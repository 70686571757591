<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>MARCH BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/march-birthstones-aquamarine-bloodstone-1280x550.jpg" alt="">
                    <p class="mt-4">
                       Aquamarine and bloodstone, March’s two birthstones, are very different when it comes to appearance, but they share a similar reputation for protecting one’s well-being. The aquamarine birthstone evokes the colors of the sea. From deep green-blue to light, slightly greenish blue hues, faceted aquamarines are often free from inclusions and as clear as water, symbolizing purity of spirit and soul. The bloodstone birthstone is typically a dark-green cabochon that contains red spots of iron oxide, the “blood” that brings health and strength to the wearer. Read on to learn more about these two March birthstones – what they mean and where they can be found.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>AQUAMARINE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  Aquamarine’s name comes from the Latin for seawater, and ancient mariners claimed the gem would calm waves and keep sailors safe at sea. This March birthstone was also thought to bring happiness in marriage. Beryl was believed to give the wearer protection against foes in battle and litigation. It was also thought to make the wearer unconquerable and amiable, and to quicken the intellect.</p>
 
                <p>Aquamarine is not only the birthstone for March, but the gem is also given as a present on the 19th wedding anniversary. As for famous ones, in 1936 the government of Brazil gave First Lady Eleanor Roosevelt a dark blue rectangular step cut aquamarine that weighed 1,298 carats (ct). It was the larger of two stones faceted from a piece of aquamarine rough that itself weighed an impressive 2.9 pounds (1.3 kilograms). It is now housed at the Franklin D. Roosevelt Presidential Library and Museum in Hyde Park, New York. A celebrated attraction at the Smithsonian Institution is the 10,363 ct (about 4.6 pounds) Dom Pedro Aquamarine – believed to be the largest faceted aquamarine in the world. The approximately 14 inch (36 centimeter) high obelisk was fashioned by acclaimed German lapidary Berndt Munsteiner using the fantasy cut technique.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/Roosevelt-Aquamarine-545x600.png" alt="">
  

            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-md-10">

                <h1><strong>BLOODSTONE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                 Bloodstone, also known as heliotrope, is a variety of chalcedony (cryptocrystalline quartz) that is traditionally semi-translucent to opaque dark green jasper with red inclusions of iron oxides, especially hematite. Bloodstone’s alternate name heliotrope comes from the ancient Greek word that means “to turn the sun.” In ancient times, these March birthstones were believed to turn the sun red if they were placed in water. The name bloodstone derives from the belief by some that the color pattern has religious significance, representing the blood of Christ.
                 </p>
                 <p>

                Several metaphysical properties have been attributed to this March birthstone. They  include increasing strength, giving invisibility, and preserving health and youth. In modern times, many believe the bloodstone birthstone to be a lucky charm, as it is prized by athletes and others who want to grow their strength. Even today in India, fine bloodstones may be crushed into a powder and used as an aphrodisiac.
                </p>
                <p>

                Although bloodstone does not share the same beauty as the aquamarine March birthstone, many prize bloodstone for its special properties. Bloodstones used as gems are typically cut as cabochons, though some striking examples are faceted.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/234013-engraved-bloodstone-750x750.png" alt="">
  

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'MarchBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
