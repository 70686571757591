<template>
    <div class="container-fluid mb-5 px-0">
    <div class="container mt-5 pb-5 text-center">
        <h1 class="mt-5" style="font-size:26px"><strong>BIRTHSTONES FOR EACH MONTH</strong></h1>
         <!-- Go to www.addthis.com/dashboard to customize your tools -->
        <div class="addthis_inline_share_toolbox"></div>
        <!-- <hr class="align-center" style="width:50px"> -->
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-md-10">
                <p>
                    Birthstones are a fun, popular and colorful introduction to the world of gemstones. They appeal to audiences around the world regardless of gender, age, nationality or religion. Birthstones carry secrets, attributes and lore that are unique to each gemstone. There are countless myths and legends around the many different powers and attributes that birthstones have. Whether or not you believe these legends, it’s hard to disagree that learning about birthstones can be an educational and entertaining experience. Discover what makes your birthstone a powerful statement of your finest qualities by clicking on the month you were born in below.
                </p>
            </div>
        </div>
    </div>
   
    <div class="container d-none d-sm-block">
        <div class="row d-flex justify-content-center" >
            <div class="col-md-10" style="border-bottom: 1px dotted #ccc;">
                <div class="accordion row mt-5 d-flex justify-content-center" v-if="birthmonths.length > 0">
                    <!-- MONTHS -->
                    <div v-for="month in birthmonths" :key="month.id" class="col-6 col-md-3 px-0 months" :class="bulan == month.id ? 'active':'nonactive'" :id="month.id"> 
                        <div v-if="month.id == 4 || month.id == 5 || month.id == 6 || month.id == 7">
                            <button class="btn d-none d-sm-block"  @click="getBulan(month.id)"  type="button" style="width:100%; height:100%; border:none;" data-toggle="collapse" :data-target="'#' + month.name" aria-expanded="false" :aria-controls="month.name">
                                <h6 class="mt-3" style="position:absolute;z-index:1;left: 50%;  margin-left: -40px;"><strong>{{ month.name }}</strong></h6>
                                <img :src="base_url + '/storage/images/birthstones/' + month.month_img_small" alt="Product"  >
                                
                            </button>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

            <!-- COLLAPSE -->
            <div class="d-none d-sm-block">
            <div v-for="month in birthmonths" :key="month.id" class="collapse" :id="month.name">
                <div v-if="month.id == 4 || month.id == 5 || month.id == 6 || month.id == 7">
                <div v-if="bulan == month.id">
                <div class="card card-body" style="border:none; background:#EFEFEF;">
                    <div class="container">

                        <div class="row d-flex justify-content-center text-center py-5">
                            <div class="col-md-10">
                                <div class="row">
                                <div class="col-md-4 py-5">
                                    <h2><strong>{{ month.name }}</strong></h2>
                                    <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                                    <p class="leads" v-html="month.subtitle"></p>
                                </div>
                                <div class="col-md-4">
                                     <img :src="base_url + '/storage/images/birthstones/' + month.month_img_med" :alt="'BIRTHSTONE' + month.name" >
                                </div>
                                <div class="col-md-4">
                                    <h6><strong>BIRTHSTONE OVERVIEW</strong></h6>
                                    <p><small v-html="month.overview_desc"></small></p>
                                    <button class="btn btn-primary" @click="pilihBirthstone(month.slug)">
                                        MORE ABOUT THIS BIRTHSTONE
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
            <!-- END COLLAPASE -->

    <div class="container d-none d-sm-block">
        <div class="row d-flex justify-content-center" >
            <div class="col-md-10" style="border-bottom: 1px dotted #ccc;">
                <div class="accordion row mt-0 d-flex justify-content-center" v-if="birthmonths.length > 0">
                    <!-- MONTHS -->
                    <div v-for="month in birthmonths" :key="month.id" class="col-6 col-md-3 px-0 months" :class="bulan == month.id ? 'active':'nonactive'" :id="month.id"> 
                        <div v-if="month.id == 8 || month.id == 9 || month.id == 10 || month.id == 11">
                            <button class="btn" :class="bulan == month.id ? 'active':'nonactive'" @click="getBulan(month.id)"  type="button" style="width:100%; height:100%; border:none;" data-toggle="collapse" :data-target="'#' + month.name" aria-expanded="false" :aria-controls="month.name">
                                <h6 class="mt-3" style="position:absolute;z-index:1;left: 50%;  margin-left: -40px;"><strong>{{ month.name }}</strong></h6>
                                <img :src="base_url + '/storage/images/birthstones/' + month.month_img_small" alt="Product"  >
                                
                            </button>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <!-- COLLAPSE -->
     <div class="d-none d-sm-block">
            <div v-for="month in birthmonths" :key="month.id" class="collapse" :id="month.name">
                <div v-if="month.id == 8 || month.id == 9 || month.id == 10 || month.id == 11">
                <div v-if="bulan == month.id">
                <div class="card card-body" style="border:none; background:#EFEFEF;">
                    <div class="container">

                        <div class="row d-flex justify-content-center text-center py-5">
                            <div class="col-md-10">
                                <div class="row">
                                <div class="col-md-4 py-5">
                                    <h2><strong>{{ month.name }}</strong></h2>
                                    <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                                      <p class="leads" v-html="month.subtitle"></p>
                                </div>
                                <div class="col-md-4">
                                     <img :src="base_url + '/storage/images/birthstones/' + month.month_img_med" :alt="'BIRTHSTONE' + month.name" >
                                </div>
                                <div class="col-md-4">
                                    <h6><strong>BIRTHSTONE OVERVIEW</strong></h6>
                                    <p><small v-html="month.overview_desc"></small></p>
                                    <button class="btn btn-primary" @click="pilihBirthstone(month.slug)">MORE ABOUT THIS BIRTHSTONE</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
            <!-- END COLLAPASE -->
    

    <div class="container d-none d-sm-block">
        <div class="row d-flex justify-content-center" >
            <div class="col-12 col-md-10" >
                <div class="accordion row mt-0 d-flex justify-content-center" v-if="birthmonths.length > 0">
                    <!-- MONTHS -->
                    <div v-for="month in birthmonths" :key="month.id" class="col-6 col-md-3 px-0 months" :class="bulan == month.id ? 'active':'nonactive'" :id="month.id"> 
                         <div v-if="month.id == 13 || month.id == 14 || month.id == 15 || month.id == 16 ">
                            <button class="btn" :class="bulan == month.id ? 'active':'nonactive'" @click="getBulan(month.id)"  type="button" style="width:100%; height:100%; border:none;" data-toggle="collapse" :data-target="'#' + month.name" aria-expanded="false" :aria-controls="month.name">
                                <h6 class="mt-3" style="position:absolute;z-index:1;left: 50%;  margin-left: -40px;"><strong>{{ month.name }}</strong></h6>
                                <img :src="base_url + '/storage/images/birthstones/' + month.month_img_small" alt="Product"  >
                                
                            </button>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <!-- COLLAPSE -->
     <div class="d-none d-sm-block">
            <div v-for="month in birthmonths" :key="month.id" class="collapse" :id="month.name">
                <div v-if="month.id == 13 || month.id == 14 || month.id == 15 || month.id == 16 ">
                <div v-if="bulan == month.id">
                <div class="card card-body" style="border:none; background:#EFEFEF;">
                    <div class="container">

                        <div class="row d-flex justify-content-center text-center py-5">
                            <div class="col-md-10">
                                <div class="row">
                                <div class="col-md-4 py-5">
                                    <h2><strong>{{ month.name }}</strong></h2>
                                    <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                                     <p class="leads" v-html="month.subtitle"></p>
                                </div>
                                <div class="col-md-4">
                                     <img :src="base_url + '/storage/images/birthstones/' + month.month_img_med" :alt="'BIRTHSTONE' + month.name" >
                                </div>
                                <div class="col-md-4">
                                    <h6><strong>BIRTHSTONE OVERVIEW</strong></h6>
                                    <p><small v-html="month.overview_desc"></small></p>
                                    <button class="btn btn-primary" @click="pilihBirthstone(month.slug)">MORE ABOUT THIS BIRTHSTONE</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
            <!-- END COLLAPASE -->


          



<!-- mobile -->
 <div class="container d-block d-sm-none">
        <div class="row d-flex justify-content-center" >
            <div class="col-md-10" style="border-bottom: 1px dotted #ccc;">
                <div class="accordion row mt-5 d-flex justify-content-center" v-if="birthmonths.length > 0">
                    <!-- MONTHS -->
                    <div v-for="month in birthmonths" :key="month.id" class="col-6 col-md-3 px-0 months" :class="bulan == month.id ? 'active':'nonactive'" :id="month.id"> 
                        
                            <button class="btn d-none d-sm-block"  @click="getBulan(month.id)"  type="button" style="width:100%; height:100%; border:none;" data-toggle="collapse" :data-target="'#' + month.name" aria-expanded="false" :aria-controls="month.name">
                                <h6 class="mt-3" style="position:absolute;z-index:1;left: 50%;  margin-left: -40px;"><strong>{{ month.name }}</strong></h6>
                                <img :src="base_url + '/storage/images/birthstones/' + month.month_img_small" alt="Product"  >
                                
                            </button>  
                            
                            <button class="btn d-block d-sm-none"  @click="getBulan(month.id)"  type="button" style="width:100%; height:100%; border:none;" data-toggle="modal" :data-target="'#' + month.slug" aria-expanded="false" :aria-controls="month.name" >
                                <h6 class="mt-3" style="position:absolute;z-index:1;left: 50%;  margin-left: -40px;"><strong>{{ month.name }}</strong></h6>
                                <img :src="base_url + '/storage/images/birthstones/' + month.month_img_small" alt="Product"  >
                                
                            </button>  
                            <!-- Modal -->
<div class="modal fade w-100 m-0" :id="month.slug" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
     <div v-if="bulan == month.id">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-5">
            <div class="col-md-4 py-0 text-center">
                <h2><strong>{{ month.name }}</strong></h2>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                    <p class="leads" v-html="month.subtitle"></p>
            </div>
            <div class="col-md-4 py-0">
                    <img :src="base_url + '/storage/images/birthstones/' + month.month_img_med" :alt="'BIRTHSTONE' + month.name" >
            </div>
            <div class="col-md-4">
                <h6><strong>BIRTHSTONE OVERVIEW</strong></h6>
                <p><small v-html="month.overview_desc"></small></p>
                <button class="btn btn-primary" data-dismiss="modal" @click="pilihBirthstone(month.slug)">MORE ABOUT THIS BIRTHSTONE</button>
            </div>
      </div>
      
    </div>
  </div>
</div>
</div>
                        </div>
                    </div>
                </div>
            </div>
      
    </div>


            
           




</div>
</template>

<script>
export default {
    name: 'Birthstones',
    components: {

    },
    data() {
        return {
            bulan:null,
            birthmonths:[],
            base_url: process.env.VUE_APP_API_URL,
        }
    },
    metaInfo() {
        return { 
            title: "Birthstones | Batumuliashop.com",
            meta: [
                { name: 'description', content:  'Cek Birthstones Anda disini. Anda akan menemukan batu mulia yang cocok untuk Anda, dengan segala makna dan manfaatnya'},
                { property: 'og:title', content: 'Birthstones | Batumuliashop.com'},
                { property: 'og:site_name', content: 'Batumuliashop.com'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    mounted(){
        this.axios.get('/birthstone-month')
        .then((response) => {
          let {data } = response.data
          this.birthmonths = data
        })
        .catch((error) => {
          let {data} = error.response
          this.setAlert({
              status : true,
              text  : data.message,
              color  : 'danger',
        }) })
    },
  methods:{
      getBulan(e){
          this.bulan = e
      },
      pilihBirthstone(pilihan){
            this.$router.push({path:'/birthstones/'+pilihan})

      }
  }
}
</script>
<style scoped>
.active {
    background: #efefef;
}
.nonactive {
    background: none;

}
.months {
    border-right: 1px dotted #ccc;
}
.months:nth-child(4), .months:nth-child(8), .months:nth-child(12){
    border-right: none;
}


</style>

