<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>JUNE BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/June-Birthstone-Pearl-Alexandrite-Moonstone-2-credit.jpg" alt="">
                    <p class="mt-4">
                        If you were born in the month of June, you are lucky enough to have three birthstones to call your own. June is one of only three months (the others are August and December) that has three birthstones, giving you a variety of beautiful birthstone choices.</p>
                    <p>
                    The June birthstones are pearl, alexandrite and moonstone. With so many attractive options, individuals with June birthdays can have a birthstone that fits their mood or budget, due to the different colors and price points these gems offer.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>PEARL BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  This enchanting June birthstone originates from oceans, lakes and rivers around the world. It is a timeless wardrobe staple, beloved by women of all ages. The origin of pearls fascinated our forebears. Ancients from the Middle East believed that pearls were teardrops fallen from heaven. The Chinese fancied that the June birthstone came from the brain of a dragon. Christopher Columbus and his contemporaries thought that mollusks formed pearls from dew drops.</p>
                <p>
                Pearls are organic gems that grow inside the tissue of a living saltwater or freshwater mollusk (either an oyster or a mussel). Natural pearls form when the mollusk secretes a substance called nacre around an irritant such as a piece of sand or a parasite that has invaded its shell. Cultured pearls are a product of human intervention. Technicians implant a piece of mantle tissue alone (common for freshwater cultured pearls) or with a mother-of-pearl shell bead (all saltwater) into a host mollusk. The mollusk covers the irritant with nacre, just like a natural pearl. Cultured pearls are raised in pearl farms – saltwater or freshwater operations where the mollusks are cleaned, protected from predators and eventually harvested. Thousands of years of pearl fishing have decimated the natural pearl beds, so cultured pearls account for the vast majority of pearl sales today. These cultured pearl birthstones come in a dazzling array of sizes, colors and shapes.</p>
                <p>
                Pearls have long been associated with purity, humility and innocence. So it may be said that the June birthstone meaning is "sweet simplicity." As such, pearls were traditionally given as a wedding gift.</p>
                <p>
                The pearl birthstone was also thought to have beneficial properties. In the ancient Sanskrit text the Atharvaveda, pearls were said to bestow long life and prosperity. In Asia, pearls were believed to help alleviate indigestion and hemorrhages. Some 19th century Arab physicians maintained that pearl powder improved eyesight, quieted nervous tremors and eased depression.</p>
                <p>
                One of the most famous natural pearls is the 50.56 carat (ct) La Peregrina. About the size of a pigeon’s egg, the drop shaped pearl was discovered in the 1500s in the Gulf of Panama. It became a prized possession of European royalty. Richard Burton eventually gifted it to Elizabeth Taylor in 1969; Christie’s New York auctioned the Cartier necklace containing La Peregrina for $11.8 million in 2011.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/146688-La-Peregrina-750x750.png" alt="">
               

            </div>
        </div>

        <div class="row d-flex justify-content-center mt-5">
            <div class="col-md-10">

                <h1><strong>MOONSTONE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  Moonstone is the best-known gem of the feldspar group of minerals. It is renowned for its adularescence, the light that appears to billow across a gemstone, giving it a special glow. The finest moonstones show a blue sheen against a colorless background. This June birthstone has been associated with both the Roman and Greek lunar deities. Hindu mythology claims that it is made of solidified moonbeams. Moonstone is often associated with love, passion and fertility; it is believed to bring great luck.</p>
                <p>
                Great designers of the Art Nouveau era (1890s–1910s), such as René Lalique and Louis Comfort Tiffany, featured moonstone in their fine jewelry. The moonstone birthstone came to the forefront again during the 1960s “flower child” movement and with New Age designers of the 1990s.
 
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/216797-gold-moonstone-brooch-900x507.png" alt="">
               

            </div>
        </div>

        <div class="row d-flex justify-content-center mt-5">
            <div class="col-md-10">

                <h1><strong>ALEXANDRITE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                 Alexandrite is the rare variety of the mineral chrysoberyl that changes color in different lighting. Most prized are those alexandrite birthstones that show a vivid green to bluish green in daylight or fluorescent light, and an intense red to purplish red in incandescent light. Major alexandrite deposits were first discovered in 1830 in Russia’s Ural Mountains. The gem was named after the young Alexander II (1818–1881), heir apparent to the throne. Alexandrite caught the country’s attention because its red and green colors mirrored the national military colors of imperial Russia. </p>
                <p>
                When certain types of long, thin inclusions are oriented parallel to each other in this June birthstone, they can create another phenomenon, called chatoyancy or the cat’s-eye effect. Few gems are as fascinating – or as stunning – as cat’s-eye alexandrite.
 
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/147163-alexandrite-illumination-900x507.png" alt="">
               

            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 pt-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">
                <p>
                 June is a month for celebrations, be it weddings, anniversaries, graduations or birthdays. And what better way to celebrate than with a June birthstone. Those who were born in June are lucky to have three gorgeous birthstones to choose from. Now you know how to pick one of these June birthstones for yourself or a loved one born in the month of June.
 
 
                </p>
               

            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'JuneBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
