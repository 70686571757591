<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>FEBRUARY BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/february-birthstone-amethyst-1280x550.jpg" alt="">
                    <p class="mt-4">
                       If you were born in February, your birthstone is amethyst – the purple variety of quartz that has captivated mankind for millennia. Its lilac to deep purple hues can be cut into many shapes and sizes, and it can be manufactured in a lab as well as mined. Amethyst, the February birthstone, can be found in the collections of royal families throughout Europe and Asia. Now it’s within reach of most consumers. Consider buying a stunning amethyst for the king or queen of your heart – or treat yourself to a royal present. If your birthday is in February, then wearing an amethyst can also be a symbol of personal empowerment and inner strength.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>AMETHYST BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  The name “amethyst” derives from the Greek amethystos, which means “a remedy against drunkenness,” a benefit long ascribed to the purple birthstone. Because of its wine-like color, early Greek mythology associated the gem with Bacchus, the god of wine. Amethyst was also believed to keep the wearer clear headed and quick witted in battle and business affairs. Renaissance Europeans thought it calmed lovers overrun by passion.
                </p>
                <p>
                  Amethyst is the gem traditionally given for the sixth wedding anniversary. Wear it in celebration of your wedding nuptials or as your February birthstone and you’ll be in royal company: Catherine the Great (Empress Catherine II of Russia, 1729–1796) had a penchant for the gem and decked herself in amethyst necklaces, earrings and other ornaments. The famous jewelry connoisseur Wallis, Duchess of Windsor (1896–1986), made a memorable statement when she wore a lavish Cartier-designed amethyst bib necklace to a gala in Versailles in 1953.
                </p>

                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/cartier-amethyst-necklace-900x507.png" alt="">
                <p>
                    Historically, royals have admired the deep purple hue of the February birthstone since at least the days of Alexander the Great. Amethyst lore also includes several claims to mystical powers, including that it would convey strength and wit to those who wore it. If you celebrate a February birthday, wearing an amethyst can be a symbol of personal empowerment and inner strength.


                </p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'FebruaryBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
    mounted() {

        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
