<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>DECEMBER BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/december-birthstones-turquoise-tanzanite-zircon-1280x550.jpg" alt="">
                    <p class="mt-4">
                       If you had to pick one December birthstone, which would it be: tanzanite, turquoise or zircon? From the blue to bluish purple of tanzanite, to the intense blue and green of turquoise, to the rainbow varieties of zircon – there’s a color for everyone. If blue is what you’re looking for, all three December birthstones have their own unique take on this favorite hue. Whatever your color, style or budget preferences may be, we can help you pick the right December birthstone for you or a loved one.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>TURQUOISE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  Turquoise is a semi-translucent to opaque gem that ranges from blue to green and often has veins of matrix (remnants of the rock in which it formed) running through it. This December birthstone has been cherished for millennia. The pharaohs and other rulers of ancient Egypt adorned themselves with it. Chinese artisans carved it more than 3,000 years ago.
                </p>
                <p>
 
                    The turquoise birthstone was thought to possess many beneficial powers, like guaranteeing health and good fortune. From the 13th century on, it was believed to protect the wearer from falling (especially off horses), and would break into several pieces at the approach of disaster. Hindu mystics maintained that seeing a turquoise after beholding the new moon ensured fantastic wealth.</p>
                    <p>
 
                    This turquoise birthstone also played an important role in the lives of Native Americans. The Apache thought turquoise could be found by following a rainbow to its end. They also believed that attaching the December birthstone to a bow or firearm made one’s aim more accurate. The Pueblo maintained that turquoise got its color from the sky, while the Hopi thought the gem was produced by lizards scurrying over the earth.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/80913-46.28ct-turquoise-900x507.png" alt="">
                <p>
                    This December birthstone adorns the funerary mask of King Tut, who ruled Egypt more than 3,000 years ago. It also appears in jewelry belonging to more modern royalty: Wallace Simpson (1896–1986), Duchess of Windsor (the woman for whom King Edward VIII gave up his throne), wore a famous amethyst and turquoise necklace made by Cartier. Turquoise is also the gem of the 11th wedding anniversary.
                </p>
                <p>
 
                In European tradition, the gift of a turquoise ring means “forget me not.” Turquoise is considered a national treasure in Tibet, where it is believed to grant health, good fortune and protection from evil. December's birthstone also imparts peace to those who wear it.


                </p>

            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">

                <h1><strong>TANZANITE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  Tanzanite may be a relative newcomer to the world of colored stones, but it was one of the most exciting gem discoveries of the 20th century. Blue stones emerging from Tanzania were identified as the mineral zoisite in 1962. Not until 1967, though, did prospectors locate the primary source for this December birthstone: the Merelani Hills. It was eventually named tanzanite in honor of its country of origin. The tanzanite birthstone is often described as “velvety,” mostly because of its deep and saturated color, which ranges from a pure rich blue to violet, with the blue considered most valuable.
                </p>
                <p>

                    Tiffany & Co. believed that tanzanite had international appeal and became its main distributor. In 1968, Tiffany launched a major advertising campaign to promote it. With its vivid colors, high clarity and potential for large cut stones, tanzanite quickly became a sensation. Today, it is not only a December birthstone, but it is also the gem for the 24th wedding anniversary.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/223998-tanzanite-ring-750x750.png" alt="">


            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">

                <h1><strong>ZIRCON BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  The origins of the word “zircon” have elicited colorful debate. Some scholars believe it comes from the Arabic word zarkun, meaning “cinnabar” or “vermilion.” Others think the source is the Persian word zargun, or “gold colored.” Considering the broad color palette for this December birthstone – red, orange, yellow, brown, green and blue – either derivation seems possible. Colorless zircon is known for its brilliance and flashes of multicolored light, called fire, which have resulted in centuries of confusion with diamond.</p><p>

                    During the Middle Ages, this December birthstone was thought to lull one into a deep sleep and scare off evil spirits. In the Hindu religion, zircon alternates with hessonite garnet as one of the nine gems of the navaratna. When worn together, the nine gems protect the wearer and bring wealth, wisdom and good health.</p>
                    <p>

                    Victorians had a fondness for blue zircon. Fine specimens can be found in English estate jewelry from the 1880s.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/80908-zircon-colors-600x750.png" alt="">


            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'DecemberBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
