import { render, staticRenderFns } from "./Birthstones.vue?vue&type=template&id=4ed494c9&scoped=true&"
import script from "./Birthstones.vue?vue&type=script&lang=js&"
export * from "./Birthstones.vue?vue&type=script&lang=js&"
import style0 from "./Birthstones.vue?vue&type=style&index=0&id=4ed494c9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed494c9",
  null
  
)

export default component.exports