<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>MAY BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/may-birthstone-emerald-1280x550.jpg" alt="">
                    <p class="mt-4">
                      Emerald, the birthstone for May, has been beloved for millennia, evoking rebirth and renewal. Widely regarded as the definition of green, emerald is the perfect color for spring. From the poetic description of Ireland as “the Emerald Isle” to the vibrant green of the famed gemstone itself—the May birthstone emerald has captured hearts and minds through the ages.</p>
 <p>
Variations of this rich green color suggest soothing, lush gardens. Legend has it that emerald has the power to make its wearer more intelligent and quick-witted, and it was once believed to cure diseases like cholera and malaria. Today, it's the gemstone given for the 20th and 35th wedding anniversaries.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>EMERALD BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   From Egyptian pharaohs to Inca emperors, emerald has enchanted royalty. Cleopatra was known to have a passion for emerald and used it in her royal adornments. The legendary Crown of the Andes, fashioned in colonial South America, is one example of how the Spanish revered the May birthstone. According to lore, its largest stone—now called the Atahualpa emerald—was taken from the last Inca emperor, Atahualpa, by conquistador Francisco Pizarro. The emerald and gold treasures recovered from the sunken 17th century Spanish galleon Nuestra Señora de Atocha represent a small fraction of the colonial riches sent to Spain from the New World.
                </p>
              
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/crown-of-andes-750x750.png" alt="">
                <p>
                   The word “emerald” comes from smaragdos, ancient Greek for a green gem. Roman author Pliny the Elder, who died in the 79 CE eruption of Mt. Vesuvius, wrote in his encyclopedic Natural History that “nothing greens greener.” He also stated that the May birthstone had therapeutic properties that helped gem cutters: “(they) have no better method of restoring their eyes than by looking at the emerald, its soft, green color comforting and removing their weariness and lassitude.” Science now proves this belief: The color green relieves stress and eye strain.</p>
                <p>
                The green birthstone was also thought to have magical powers. By placing it under the tongue, one could see into the future. Some believed it made one an eloquent speaker and exposed lovers who made false promises.


                </p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'MayBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
