<template>
    <div class="container my-5 text-center">
        <div class="row d-flex justify-content-center">
            <div class="col-md-8">
                <h1 class="my-5">Unique Jewelry Designs</h1>
                <p>
                    Browse a selection of beautiful and unique engagement rings we’ve created recently for our customers.
                    Our design specialists can guide you through bringing your unique vision to life.
                </p>
            </div>
        </div>
        <div class="row d-flex justify-content-center mt-4 mb-5">
            <div class="col-md-10">
                <div class="row">
                
                    <div class="col-md-3 my-2 px-2"  v-for="jewelry in jewelries" v-bind:key="jewelry.id">
                        <!-- <img class="img-fluid w-100" src="/img/custom_jewelry/1.png" alt=""> -->
                          <img :src="base_url + '/storage/images/custom-jewelry/' + jewelry.photo" alt="Custom Jewelry" class="img-fluid mx-auto" style="float:none" >
                    </div>
                  
                </div>
            </div>
            
        </div>
       

    </div>
</template>
<script>
export default {
  name: 'JewelryDesign',
   data() {
      return {
        jewelries:[],
         base_url: process.env.VUE_APP_API_URL,
      }
   },
  components: {

  },
   metaInfo() {
        return { 
            title: "Unique Jewelry Designs | Batumuliashop.com",
            meta: [
                { name: 'description', content:  'Every Design is Unique. Anda dapat request design custom sesuai yang Anda inginkan'},
                { property: 'og:title', content: 'Unique Jewelry Designs | Batumuliashop.com'},
                { property: 'og:site_name', content: 'Batumuliashop.com'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    mounted() {

      
      this.axios.get('/custom-jewelries')
       .then((response) => {
          let {data } = response.data
          this.jewelries = data
        })
       .catch((error) => {
          let {data} = error.response
          this.setAlert({
              status : true,
              text  : data.message,
              color  : 'danger',
        }) })

    },

}
</script>