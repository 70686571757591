<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>JANUARY BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/january-birthstone-garnet-1280x550.jpeg" alt="">
                    <p class="mt-4">
                        Those born in January are lucky to have the beautiful and diverse garnet as their birthstone. Garnets are commonly red but also come in an extraordinary range of beautiful colors, including orange, yellow, purple and vibrant green. There are even garnets that change color from blue to purple in different lighting. Some believe the true value of the garnet birthstone is its power to bring the wearer good health, wealth and happiness.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>GARNET BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   The name “garnet” originates from the medieval Latin granatus, meaning “pomegranate,” in reference to the similarity of the red color. Garnets have been used since the Bronze Age as gemstones and abrasives. Necklaces studded with red garnets adorned the pharaohs of ancient Egypt. Signet rings in ancient Rome featured garnet intaglios that were used to stamp the wax that secured important documents. The clergy and nobility of the Middle Ages had a preference for red garnets.
                </p>
                <p>
                   Garnet is actually a group of several minerals. Five of these – pyrope, almandine, spessartine, grossular and andradite – are important as gems. Pyrope and almandine range from purple to red. Spessartine is found in exciting oranges and yellows, while andradite is mostly yellow to green (the gem variety demantoid). Grossular may have the widest range, from colorless through yellow to reddish orange and orangy red, as well as a strong vibrant green called tsavorite.
                </p>
                <p>
                   The Smithsonian’s antique pyrope hair comb is one of the most famous pieces of garnet jewelry (pyrope is from the Greek pyrōpos, which means “fiery-eyed”). A large rose-cut garnet sits at the crest, much like a queen serenely surveying her court. The pyrope garnets that decorate this tiara-like jewel came from the historic mines in Bohemia (now part of the Czech Republic), and these rich red beauties were extremely popular during the Victorian era (1837–1901), when this piece was fashioned.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/antique-pyrope-comb-900x507.png" alt="">
                <p>
                    Curious about your garnet birthstone’s reputed health benefits? According to Indian astrology, garnet helps eliminate negative feelings (depression, guilt) and instill greater self-confidence and mental clarity to promote creative thinking and peace of mind. In ancient and medieval times, gems like garnet were also thought to be remedies for inflammatory diseases and to soothe the angry heart.


                </p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'JanuaryBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
