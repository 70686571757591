<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>JULY BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/july-birthstone-ruby-1280x550.jpg" alt="">
                    <p class="mt-4">
                       Ruby is the July birthstone – and it’s one of the most coveted of gems. The name is derived from the Latin word ruber, meaning “red” – the color of love and passion. Few things catch the eye like the ruby birthstone. The finest color of the birthstone for July is a deep red with a hint of purple, called “pigeon’s blood” in the trade. A variety of the mineral corundum, ruby gets its color from trace amounts of the element chromium. The more chromium, the stronger the red. Here’s what you need to know about this beautiful July birthstone so you can better choose one for yourself or a loved one who was born in the month of July.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>RUBY BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   In ancient India, ruby was called the “king of precious stones” for its rarity, hardness (second only to diamond), beauty and seemingly mystical powers. Long associated with the life force blood, ruby was a symbol of power and youthful energy in Indian jewelry. In past centuries, some believed this birthstone for July could predict misfortune or danger, and others claimed it would cure inflammatory diseases and soothe anger. Burmese warriors believed it made them invincible in battle. Medieval Europeans maintained that rubies bestowed health, wisdom, wealth and success in love.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/75497-ruby-necklace-900x507.png" alt="">
                

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'JulyBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
