<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>NOVEMBER BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/204978-november-birthstones-citrine-topaz-1280x550.jpg" alt="">
                    <p class="mt-4">
                        Those with November birthdays have two beautiful birthstones to choose from: topaz and citrine. Topaz comes in a rainbow of colors; citrine is prized for its charming yellow and orange hues. Both November birthstones are known to have calming energies while bringing fortune and warmth to the wearer. Most topaz and citrine birthstones are affordably priced, as good-quality gems are not as rare as for many of their counterparts. This means that those born in November have many options to choose from. Your challenge will be deciding which one to pick.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>TOPAZ BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   The variety of topaz hues includes colorless, light blue, yellow, orange, pink, violet, brown and, very rarely, red. The vast majority of blue topaz seen today is the permanent result of treating colorless topaz with irradiation and heating. The rainbow effect seen in “Mystic Topaz” is created by coating colorless topaz with a thin artificial film.
                </p>
                <p>
                    Some believe the word “topaz” comes from the Sanskrit word tapas, which means “fire.” Others trace it back to the Greek topazos. This November birthstone was long thought to have many benefits. The ancient Greeks believed that topaz gave them strength. From the 1300s to the 1600s, Europeans thought it could thwart magic spells and dispel anger. For centuries, many people in India have believed that topaz worn above the heart assures long life, beauty and intelligence.
                </p>
                <p>

                    The distinctly pinkish orange Imperial topaz has aristocratic cachet. It is commonly believed that the name originated with the Russian royal family’s insistence on keeping the finest colors of this gem, which was mined in Russia’s Ural Mountains, exclusively for their use. An alternate explanation, especially popular in Brazil, is that it dates from an 1881 visit by Brazilian Emperor Pedro II to Ouro Preto—the town closest to Brazil’s most productive topaz mines–and the gift of a reddish topaz to him.
                </p>
                <p>
 
                    Blue topaz is the gem of the fourth wedding anniversary, and Imperial topaz is the gem of the 23rd wedding anniversary.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/145509-imperial-topaz-crystal-900x507.png" alt="">


            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" >
            <div class="col-md-10">

                <h1><strong>CITRINE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   This November birthstone is the transparent yellow to brownish orange variety of quartz, which has been used in jewelry for thousands of years. Citrine has been a popular gemstone since ancient times and has shared a history of mistaken identities with the other November birthstone, topaz. As a result, people thought citrine had the same powers as topaz. They believed the citrine birthstone could soothe tempers and calm the wearer.</p>
                   <p>

                    The ancient Greeks carved rock crystal ornaments that glistened like permafrost. Roman pontiffs wore rings set with massive purple amethysts, and citrine has been reported in Roman jewelry. It was particularly popular in colorful Scottish jewelry from the Victorian era. Citrine, believed to derive from the French word for “lemon” (citron), is given for the thirteenth wedding anniversary.</p>
                    <p>

                    Today, most of the citrine in the marketplace results from the heat treatment of amethyst. With its ready availability in a broad range of sizes, citrine birthstone is one of the most affordable and desired yellow gemstones.
                                    </p>

                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/197107-brazilian-citrine-900x507.png" alt="">


            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'NovemberBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },
      mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
<style scoped>

</style>
